import styled, { device } from "@theme/utils";
import WorkingProcessSectionBg from "../../../data/images/working/bg.png";

export const AboutSection = styled.section`
    background-image: url(${WorkingProcessSectionBg});
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 130px 0px 150px;
    }
`;
export const DescriptionText = styled.span`
    .descText {
        color: #fff;
        font-size: 20px !important;
    }
`;
