import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../../../components/title";
import AboutMore from "../../../components/about-more";
import { AboutSection, DescriptionText } from "./style";

const AboutUsSection = ({ data }) => {
    return (
        <AboutSection>
            <Container>
                {data?.section_title && (
                    <SectionTitle
                        headingTitle={data?.section_title.title}
                        {...data.section_title}
                    />
                )}
                <Row>
                    {data?.content &&
                        data?.content.map((item, index) => {
                            return (
                                <Col key={index} className="col-12 mb-4">
                                    <AboutMore description={item.text} />
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </AboutSection>
    );
};
AboutUsSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            title: PropTypes.string,
            icon: PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            }),
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                alt: PropTypes.string,
            })
        ),
    }),
};
export default AboutUsSection;
